<template>
	<div class="home">
		<salesJournal></salesJournal>
	</div>
</template>

<script>
import salesJournal from '@/components/pages/report/salesJournal/salesJournalRequest.vue';

export default {
	components: {
		'salesJournal': salesJournal,
	}
};
</script>

<style>

</style>
